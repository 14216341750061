<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">שיוך {{ isEscort ? 'מלווים' : 'סטודנטים' }} לקבוצה</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section style="height: 500px" class="modal-card-body">
        <div class="DialogReportPaid__Component__Loading">
            <b-radio v-if="!isEscort" v-model="radio" name="name" native-value="manual">
              הוספה ידנית
            </b-radio>
            <b-radio v-if="!isEscort" v-model="radio" name="name" native-value="list">
              הוספה מרשימה
            </b-radio>
          <b-field>
            <FieldInlineSelect v-if="radio == 'manual'" :alert-not-exist="true" :hide-hover="true" label="מתשמש"
              :multiple="true" :clearable="true" :filterable="true" placeholder="הקלד אימייל של משתמש" :options="[]"
              :fetch-options="onSearchVehicles" @onChange="onChange" :reduce="(item) => item" v-model="userIds" />
          </b-field>
          <b-field v-if="!isEscort && radio == 'list'" label="הוספת מספר תלמידים (כתובות אימייל מופרדות ב';')">
            <b-input v-model="studentsList"></b-input>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import CoursesService from "@/services/CoursesService";
import LessonService from "@/services/LessonService";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import { ToastProgrammatic as Toast } from "buefy";
// import vSelect from "vue-select";
// import "vue-select/src/scss/vue-select.scss";

export default {
  name: "AssignGroupsToStudents",
  props: ["groups", "onSuccess", "store", "isEscort"],
  components: {
    // vSelect,
    FieldInlineSelect
  },
  computed: {
    isValidReference() {
      if (this.submitted) {
        return this.users;
      }
      return true;
    },
    courseId() {
      return this.store.state.auth.course.id;
    },
    isValidDate() {
      if (this.submitted) {
        return this.date;
      }
      return true;
    },
    isValidHour() {
      if (this.submitted) {
        return this.hour;
      }
      return true;
    },
  },
  created() {
    this.getLessons();
  },
  data() {
    return {
      isLoading: false,
      userIds: null,
      lessons: [],
      radio: 'manual',
      studentsList: null
    };
  },
  methods: {
    getLessons() {
      CoursesService.getCourse(this.courseId,
        {
          PageSize: 999,
          IsAscending: false,
          PageNumber: 1,
          ResultType: 3
        },
        false).then((r) => this.lessons = r.data.items.map((u) => ({
          Value: u.id,
          Text: `${u.id} - ${u.name}`
        }))
        );
    },
    onChange() { },
    onSearchVehicles({ search, loading, setData, setValue }) {
      if (search.length > 3) {
        loading(true);
        return CoursesService.getUsersByCourse(this.courseId, {
          Terms: search
        })
          .then((r) => {
            if (!r.data.items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.items.map((i) => ({ Value: i.id, Text: `${i.privateName} ${i.lastName} - ${i.userName}` })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    save() {
      this.submitted = true;
      if (this.userIds) {
        this.isLoading = true;
        LessonService.assignGroupsToStudents(this.courseId, {
          GroupIds: this.groups,
          MemberIds: this.userIds.map((l) => l.Value),
          AsEscorts: this.isEscort
        }).then(() => {
          this.$emit("close");
          // window.location.reload();
          this.$emit('customEvent');
          Toast.open({
            type: "is-success",
            message: "השיוך בוצע בהצלחה!",
            duration: 8000,
          });
        }).catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
          .finally(() => {
            this.isLoading = false;
          });
      } 
      if (this.studentsList) {
        this.isLoading = true;
        LessonService.assignGroupsToStudentsList(this.courseId, {
          GroupIds: this.groups,
          MemberEmails: this.studentsList.split(';'),
        }).then(() => {
          this.$emit("close");
          window.location.reload();
          Toast.open({
            type: "is-success",
            message: "השיוך בוצע בהצלחה!",
            duration: 8000,
          });
        }).catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.datetime .dropdown-content {
  width: 25%;

  .field-body .field {
    flex-direction: row-reverse;
  }
}
</style>

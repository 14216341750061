<template>
  <transition>
    <div ref="task" id="NewTask__Component">
      <div id="NewTask__Component__Header">
        <div class="is-flex">{{ activityType }} חדשה</div>
        <div @click="close">
          <b-icon class="close__icon" icon="close"> </b-icon>
        </div>
      </div>
      <div class="NewTask__Component__Title" v-if="activityType === 'משימה'">
        <input
          @input="isValid"
          type="text"
          placeholder="כותרת המשימה"
          v-model="title"
        />
      </div>
      <div class="NewTask__Component__Reminder" v-if="activityType === 'משימה'">
        <div class="NewTask__Component__Reminder__Fields">
          <div class="ml-6">
            <!-- <FieldInlineDate
              :hide-hover="true"
              label="עד תאריך"
              @onChange="onChange"
              v-model="dueDate.date"
            />

            <FieldInlineTime
              :hide-hover="true"
              label="שעה"
              @onChange="onChange"
              v-model="dueDate.time"
            /> -->
            <FieldInlineDateTime
              label="עד תאריך"
              @onChange="onChange"
              :hide-hover="false"
              position="is-bottom-left"
              v-model="dueDate.date"
            />
          </div>
          <div class="mr-6">
            <!-- <FieldInlineDate
              :hide-hover="true"
              label="תזכורת"
              @onChange="onChange"
              v-model="reminder.date"
            />

            <FieldInlineTime
              :hide-hover="true"
              label="שעה"
              @onChange="onChange"
              v-model="reminder.date"
            /> -->
            <FieldInlineDateTime
              label="תזכורת"
              @onChange="onChange"
              :hide-hover="true"
              position="is-bottom-left"
              v-model="reminder.date"
            />
          </div>
        </div>
      </div>
      <div
        class="NewTask__Component__Attributes"
        v-if="activityType === 'משימה'"
      >
        <div class="Field ml-6">
          <FieldInlineSelect
            :hide-hover="true"
            label="סוג"
            :clearable="false"
            :filterable="false"
            :optionsAsync="getTaskTypes"
            @onChange="onChange"
            v-model="taskType"
          />
        </div>
        <div class="Field">
          <FieldInlineSelect
            :hide-hover="true"
            label="דחיפות"
            :clearable="false"
            :filterable="false"
            :optionsAsync="getPriorityTypes"
            :null-is-value="true"
            @onChange="onChange"
            :value="propPriority"
            :immediateChoose="true"
            v-model="priorityType"
          />
        </div>
        <div class="Field">
          <FieldInlineSelect
            :hide-hover="true"
            label="שיוך משתמש"
            :clearable="false"
            :filterable="false"
            :optionsAsync="getUsers"
            @onChange="isValid"
            v-model="assignedUserId"
          />
        </div>
      </div>
      <div class="NewTask__Component__Editor">
        <NewActivityEditor
          :content="propBody || ''"
          @onChange="isValid"
          ref="editor"
          :placeholder="`${activityType}...`"
        />
      </div>
      <div id="NewTask__Component__Footer">
        <b-button :loading="isLoading" :disabled="!isFormValid" @click="save"
          >שמירת {{ activityType }}</b-button
        >
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint no-use-before-define: ["error", { "functions": false }] */
/* eslint no-param-reassign: ["error", { "props": false }] */
// import FieldInlineDate from "@/components/Fields/FieldInlineDate.vue";
// import FieldInlineTime from "@/components/Fields/FieldInlineTime.vue";
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import EntityEnumsService from "@/services/EntityEnumsService";
import ProfileService from "@/services/ProfileService";
import NewActivityEditor from "./NewActivityEditor.vue";

export default {
  name: "NewTask",
  props: [
    "type",
    "propTitle",
    "propReminder",
    "propAssignedUserId",
    "propBody",
    "propPriority",
    "propisAdminWithoutProfileId"
  ],
  components: {
    NewActivityEditor,
    // FieldInlineDate,
    // FieldInlineTime,
    FieldInlineSelect,
    FieldInlineDateTime,
  },
  computed: {
    activityType() {
      switch (this.type) {
        case "Task":
          return "משימה";
        case "Note":
          return "הערה";
        default:
          return "";
      }
    },
    isAdminWithoutProfileId() {
      if (this.propisAdminWithoutProfileId) return true;
      return !this.$store.state.auth.profileId && this.$store.state.auth?.user?.claims?.isAdmin === "True";
    }
  },
  created() {
    if (this.propTitle) {
      this.title = this.propTitle;
    }
    if (this.propReminder) {
      this.reminder.date = this.propReminder;
    }
    if (this.propAssignedUserId) {
      this.assignedUserId = this.propAssignedUserId;
    }
    if (this.propPriority) {
      this.priorityType = this.propPriority;
    }
  },
  mounted() {
    dragElement(this.$refs.task);
  },
  data() {
    return {
      dueDate: {
        date: this.threeDaysUpcoming(),
        time: new Date(),
      },
      reminder: {
        date: null,
        time: null,
      },
      // editor: null,
      hasContent: false,
      taskType: 3,
      priorityType: "",
      assignedUserId: null,
      title: null,
      isFormValid: false,
      isLoading: false,
    };
  },
  methods: {
    threeDaysUpcoming() {
      const dt = new Date();
      dt.setDate(dt.getDate() + 3);
      return dt.getTime();
    },
    close() {
      this.$emit("onClose");
    },
    setLoading(value) {
      this.isLoading = value;
    },
    save() {
      //   const fullDueDate = this.dueDate?.date;
      //   const fullReminderDate = this.reminder?.date;
      //   if (this.dueDate?.time) {
      //     fullDueDate.setHours(this.dueDate.time.getHours());
      //     fullDueDate.setMinutes(this.dueDate.time.getMinutes());
      //   }
      //   if (this.reminder?.time) {
      //     fullReminderDate.setHours(this.reminder.time.getHours());
      //     fullReminderDate.setMinutes(this.reminder.time.getMinutes());
      //   }
      this.$emit("onSave", {
        Title: this.title,
        Body: this.$refs.editor.getHTML(),
        TaskType: this.taskType,
        DueDate:
          typeof this.dueDate.date === "number"
            ? new Date(this.dueDate.date)
            : this.dueDate.date,
        // DueDate: fullDueDate,
        EmailReminderDate: this.reminder.date,
        // EmailReminderDate: fullReminderDate,
        AssignedUserId: this.assignedUserId,
        Priority: this.priorityType,
      });
    },
    onChange(isEmpty) {
      this.hasContent = !isEmpty;
    },
    isValid() {
      if (this.type === "Note") {
        this.isFormValid = !this.$refs.editor.isEmpty();
      } else if (this.type === "Task") {
        this.isFormValid = this.title && this.assignedUserId;
        // !this.$refs.editor.isEmpty() && this.title && this.assignedUserId;
      }
    },
    getTaskTypes() {
      return () =>
        EntityEnumsService.getTaskTypes().then((r) =>
          r.data.map((i) => ({ Value: i.Id, Text: i.Name }))
        );
    },
    getPriorityTypes() {
      return () =>
        EntityEnumsService.getTaskPriorities().then((r) => {
          const data = r.data.map((i) => ({ Value: i.Id, Text: i.Name }));
          data.unshift({ Value: "", Text: "ללא" });
          return data;
        });
    },
    getUsers() {
      if (this.propisAdminWithoutProfileId) {
        return () =>
        ProfileService.adminUsersTask().then((r) => {
          const data = r.data.map((u) => ({
            Value: u.Id,
            Text: u.FullName || u.UserName,
          }));
          this.assignedUserId = data.find((u) => u.Text === 'info@betterway.co.il')?.Value;
          this.isValid();
          return data;
      });
    }
      return () =>
        ProfileService.usersTask().then((r) => {
          const data = r.data.map((u) => ({
            Value: u.Id,
            Text: u.FullName || u.UserName,
          }));
          this.assignedUserId = r.data.find((u) => u.IsMe)?.Value;
          return data;
      });
    },
  },
};
function dragElement(element) {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;
  if (document.getElementById(`${element.id}__Header`)) {
    document.getElementById(`${element.id}__Header`).onmousedown =
      dragMouseDown;
  } else {
    element.onmousedown = dragMouseDown;
  }

  function dragMouseDown(a) {
    let e = a;
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(a) {
    let e = a;
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    element.style.top = `${element.offsetTop - pos2}px`;
    element.style.left = `${element.offsetLeft - pos1}px`;
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
</script>

<style scoped lang="scss">
#NewTask__Component {
  position: absolute;
  z-index: 9;
  background-color: white;
  border-radius: 3px;
  box-shadow: silver 0px 1px 24px 0px;

  #NewTask__Component__Header {
    border-radius: 3px;
    padding: 5px 10px;
    cursor: move;
    z-index: 10;
    background-color: #516f90;
    color: #fff;
    width: 700px;
    font-size: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .NewTask__Component__Title {
    input {
      border: none;
      padding: 15px 20px;
      margin: 5px 0;
      width: 100%;

      &:focus {
        border: none;
        outline: none;
      }

      &::placeholder {
        color: #829cb9;
      }
    }
  }
  .NewTask__Component__Reminder {
    padding: 0 20px;

    .NewTask__Component__Reminder__Fields {
      display: flex;
      padding-bottom: 5px;
      .field .is-grouped .is-grouped-centered {
        flex-direction: row-reverse !important;
      }
      & > div {
        // width: 140px;
        display: flex;
        // flex-grow: 1;
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }

  .NewTask__Component__Attributes {
    margin: 0 20px;
    padding: 0px 0;
    display: flex;
    border-bottom: 1px solid #d8d8d8;

    ::v-deep .vs__open-indicator {
      fill: #0091ae;
      transform: scale(0.6);
    }
    ::v-deep .vs__selected {
      color: #0091ae;
      font-weight: bold;
    }
    ::v-deep .vs__dropdown-toggle {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: #0091ae;
        cursor: pointer;
      }
    }
  }

  .NewTask__Component__Editor {
    cursor: default;
    padding: 10px;
    padding-bottom: 3px;

    ::v-deep .NewTask__Component__Editor_Inner > div:nth-child(1) {
      min-height: 100px;
      max-height: 300px;
      overflow-y: auto;
    }
  }

  #NewTask__Component__Footer {
    border-top: 1px solid #dfe3eb;
    padding: 10px;

    button {
      background: #ff7a59;
      border: none;
      border-radius: 3px;
      padding: 7px 12px;
      color: white;
      cursor: pointer;

      &:disabled {
        background: silver;
        cursor: not-allowed;
      }

      &:not(:disabled):hover {
        background: #fb6743;
      }
    }
  }
}
.close__icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: transparent;

  &:hover {
    background-color: #c0c0c042;
    pointer-events: default;
    border-radius: 50%;
    padding: 10px;
    transition: background 0.2s linear;
  }
}
</style>

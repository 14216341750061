<template>
  <transition name="slide-from-left">
    <div v-if="show" class="SideMenu__Component" :style="{ width }">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SideMenu",
  props: {
    width: {
      type: String,
      default: "600px",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
};
</script>

<style scoped lang="scss">
.SideMenu__Component {
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;

  ::v-deep .SideMenu__Header {
    background: rgb(1, 166, 188);
    background: linear-gradient(
      90deg,
      rgba(1, 166, 188, 1) 0%,
      rgba(0, 186, 168, 1) 100%
    );
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    h1 {
      color: white;
      font-size: 20px;
      font-weight: normal;
      margin: 0;
      padding: 0;
    }
    .SideMenu__Header__Close__Icon {
      background: transparent;
      border: none;
      outline: none;

      span {
        color: white;
        font-size: 1.5rem;
        cursor: pointer;
        border-radius: 50%;
        padding: 15px;

        &:hover {
          background-color: #77777766;
          transition: all linear 0.2s;
        }
      }
    }
  }
  ::v-deep .SideMenu__Content {
    height: 100%;

    background-color: white;
    overflow-y: auto;
    padding: 40px;

    &.Entity-Preview {
      padding: 0;
    }

    .Field__Component {
      margin-bottom: 20px;

      label {
        display: flex;
        flex-direction: column;

        span {
          font-size: 14px;
          margin-bottom: 5px;
        }
        input[type="text"] {
          border: 1px solid #cbd6e2;
          border-radius: 2px;
          padding: 9px;
          outline: none;

          &.vs--has-value {
            border: 1px solid #00b4ae !important;
          }
        }
        .vs__search::placeholder {
          color: #333;
          font-size: 14px;
        }

        & .vs__dropdown-toggle {
          padding: 4px 0;
          border-radius: 2px;
          border-color: #cbd6e2;
        }
        // select {
        //   padding: 10px 5px;

        //   option {
        //     font-size: 18px;
        //   }
        // }
      }
    }
  }
  ::v-deep .SideMenu__Footer {
    height: 80px;
    border-top: 1px solid silver;
    background: white;
    display: flex;
    position: relative;

    .show-record {
      color: #1e9eb8;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        text-decoration: underline;
      }
    }
    .show-entity-btn {
      color: white;
      position: absolute;
      left: 20px;
      background-color: #ff7a59;
      height: 45px;
      margin: 10px;
      padding: 10px 30px;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      &:hover {
        background-color: #fb6743;
      }
    }
    button {
      height: 45px;
      margin: 10px;
      padding: 10px 30px;
      border-radius: 3px;
      outline: none;
      cursor: pointer;

      &:hover {
        border-color: #fdc1b2 !important;
      }
      &:active {
        border-color: #ff7a59 !important;
        transform: scale(0.95);
      }
    }

    button.create {
      background: #ff7a59;
      color: white;
      border: none;
    }
    button.close {
      background: white;
      color: #ff7a59;
      border: none;
      border: 1px solid #ff7a59;
    }
  }
}
.slide-from-left-enter-active,
.fade-leave-active {
  transition: transform 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-from-left-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-600px);
}
@media screen and (max-width: 600px) {
  .SideMenu__Component {
    width: 100% !important;
  }
}
</style>

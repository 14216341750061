import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const getItemTypes = () => axios.get(`${BASE_URL}/api/Entities/ItemTypes`);
const getLogTypes = () => axios.get(`${BASE_URL}/api/Entities/LogTypes`);
const getLogTypesOutcomes = (logType) => axios.put(`${BASE_URL}/api/Entities/LogTypes/${logType}/Outcomes`);
const getTaskTypes = () => axios.get(`${BASE_URL}/api/Entities/TaskTypes`);
const getEntityTypes = () => axios.get(`${BASE_URL}/api/Entities/EntityTypes`);
const getTaskPriorities = () => axios.get(`${BASE_URL}/api/Entities/TaskPriorities`);

export default {
    getItemTypes,
    getLogTypes,
    getLogTypesOutcomes,
    getTaskTypes,
    getEntityTypes,
    getTaskPriorities,
};

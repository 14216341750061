<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">שיוך ליחידות לימוד</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section style="height: 500px" class="modal-card-body">
        <div class="DialogReportPaid__Component__Loading">
          <b-field label="בחר יחידות לימוד">
            <v-select dir="rtl" label="Text" v-model="lessonIds" :options="lessons" :clearable="true" :multiple="true"
              :searchable="true" :close-on-select="false" :placeholder="'בחר ערך מהרשימה'">
              <div slot="no-options">לא נמצאו ערכים דומים</div>
            </v-select>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import CoursesService from "@/services/CoursesService";
import LessonService from "@/services/LessonService";
import { ToastProgrammatic as Toast } from "buefy";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  name: "AssignGroupsToLessons",
  props: ["groups", "onSuccess", "store"],
  components: {
    vSelect,
  },
  computed: {
    isValidReference() {
      if (this.submitted) {
        return this.lessons;
      }
      return true;
    },
    courseId() {
      return this.store.state.auth.course.id;
    },
    isValidDate() {
      if (this.submitted) {
        return this.date;
      }
      return true;
    },
    isValidHour() {
      if (this.submitted) {
        return this.hour;
      }
      return true;
    },
  },
  created() {
    this.getLessons();
  },
  data() {
    return {
      isLoading: false,
      lessonIds: null,
      lessons: []
    };
  },
  methods: {
    getLessons() {
      CoursesService.getCourse(this.courseId,
        {
          PageSize: 999,
          IsAscending: false,
          PageNumber: 1,
          ResultType: 3
        },
        false).then((r) => this.lessons = r.data.items.map((u) => ({
          Value: u.id,
          Text: `${u.id} - ${u.name}`
        }))
        );
    },
    save() {
      this.submitted = true;
      if (this.isValidReference) {
        this.isLoading = true;
        LessonService.assignGroupsToLessons(this.courseId, {
          EntityIds: this.groups,
          LessonIds: this.lessonIds.map((l) => l.Value)
        }).then(() => {
          this.$emit("close");
          window.location.reload();
          Toast.open({
            type: "is-success",
            message: "השיוך בוצע בהצלחה!",
            duration: 8000,
          });
        }).catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.datetime .dropdown-content {
  width: 25%;

  .field-body .field {
    flex-direction: row-reverse;
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            "שיוך " + _vm._s(_vm.isEscort ? "מלווים" : "סטודנטים") + " לקבוצה"
          )
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body", staticStyle: { height: "500px" } },
        [
          _c(
            "div",
            { staticClass: "DialogReportPaid__Component__Loading" },
            [
              !_vm.isEscort
                ? _c(
                    "b-radio",
                    {
                      attrs: { name: "name", "native-value": "manual" },
                      model: {
                        value: _vm.radio,
                        callback: function($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio"
                      }
                    },
                    [_vm._v(" הוספה ידנית ")]
                  )
                : _vm._e(),
              !_vm.isEscort
                ? _c(
                    "b-radio",
                    {
                      attrs: { name: "name", "native-value": "list" },
                      model: {
                        value: _vm.radio,
                        callback: function($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio"
                      }
                    },
                    [_vm._v(" הוספה מרשימה ")]
                  )
                : _vm._e(),
              _c(
                "b-field",
                [
                  _vm.radio == "manual"
                    ? _c("FieldInlineSelect", {
                        attrs: {
                          "alert-not-exist": true,
                          "hide-hover": true,
                          label: "מתשמש",
                          multiple: true,
                          clearable: true,
                          filterable: true,
                          placeholder: "הקלד אימייל של משתמש",
                          options: [],
                          "fetch-options": _vm.onSearchVehicles,
                          reduce: function(item) {
                            return item
                          }
                        },
                        on: { onChange: _vm.onChange },
                        model: {
                          value: _vm.userIds,
                          callback: function($$v) {
                            _vm.userIds = $$v
                          },
                          expression: "userIds"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              !_vm.isEscort && _vm.radio == "list"
                ? _c(
                    "b-field",
                    {
                      attrs: {
                        label: "הוספת מספר תלמידים (כתובות אימייל מופרדות ב';')"
                      }
                    },
                    [
                      _c("b-input", {
                        model: {
                          value: _vm.studentsList,
                          callback: function($$v) {
                            _vm.studentsList = $$v
                          },
                          expression: "studentsList"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
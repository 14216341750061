var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", [
    _c("div", { ref: "task", attrs: { id: "NewTask__Component" } }, [
      _c("div", { attrs: { id: "NewTask__Component__Header" } }, [
        _c("div", { staticClass: "is-flex" }, [
          _vm._v(_vm._s(_vm.activityType) + " חדשה")
        ]),
        _c(
          "div",
          { on: { click: _vm.close } },
          [
            _c("b-icon", {
              staticClass: "close__icon",
              attrs: { icon: "close" }
            })
          ],
          1
        )
      ]),
      _vm.activityType === "משימה"
        ? _c("div", { staticClass: "NewTask__Component__Title" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.title,
                  expression: "title"
                }
              ],
              attrs: { type: "text", placeholder: "כותרת המשימה" },
              domProps: { value: _vm.title },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.title = $event.target.value
                  },
                  _vm.isValid
                ]
              }
            })
          ])
        : _vm._e(),
      _vm.activityType === "משימה"
        ? _c("div", { staticClass: "NewTask__Component__Reminder" }, [
            _c("div", { staticClass: "NewTask__Component__Reminder__Fields" }, [
              _c(
                "div",
                { staticClass: "ml-6" },
                [
                  _c("FieldInlineDateTime", {
                    attrs: {
                      label: "עד תאריך",
                      "hide-hover": false,
                      position: "is-bottom-left"
                    },
                    on: { onChange: _vm.onChange },
                    model: {
                      value: _vm.dueDate.date,
                      callback: function($$v) {
                        _vm.$set(_vm.dueDate, "date", $$v)
                      },
                      expression: "dueDate.date"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mr-6" },
                [
                  _c("FieldInlineDateTime", {
                    attrs: {
                      label: "תזכורת",
                      "hide-hover": true,
                      position: "is-bottom-left"
                    },
                    on: { onChange: _vm.onChange },
                    model: {
                      value: _vm.reminder.date,
                      callback: function($$v) {
                        _vm.$set(_vm.reminder, "date", $$v)
                      },
                      expression: "reminder.date"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm.activityType === "משימה"
        ? _c("div", { staticClass: "NewTask__Component__Attributes" }, [
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    "hide-hover": true,
                    label: "סוג",
                    clearable: false,
                    filterable: false,
                    optionsAsync: _vm.getTaskTypes
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.taskType,
                    callback: function($$v) {
                      _vm.taskType = $$v
                    },
                    expression: "taskType"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field" },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    "hide-hover": true,
                    label: "דחיפות",
                    clearable: false,
                    filterable: false,
                    optionsAsync: _vm.getPriorityTypes,
                    "null-is-value": true,
                    value: _vm.propPriority,
                    immediateChoose: true
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.priorityType,
                    callback: function($$v) {
                      _vm.priorityType = $$v
                    },
                    expression: "priorityType"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field" },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    "hide-hover": true,
                    label: "שיוך משתמש",
                    clearable: false,
                    filterable: false,
                    optionsAsync: _vm.getUsers
                  },
                  on: { onChange: _vm.isValid },
                  model: {
                    value: _vm.assignedUserId,
                    callback: function($$v) {
                      _vm.assignedUserId = $$v
                    },
                    expression: "assignedUserId"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "NewTask__Component__Editor" },
        [
          _c("NewActivityEditor", {
            ref: "editor",
            attrs: {
              content: _vm.propBody || "",
              placeholder: _vm.activityType + "..."
            },
            on: { onChange: _vm.isValid }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "NewTask__Component__Footer" } },
        [
          _c(
            "b-button",
            {
              attrs: { loading: _vm.isLoading, disabled: !_vm.isFormValid },
              on: { click: _vm.save }
            },
            [_vm._v("שמירת " + _vm._s(_vm.activityType))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
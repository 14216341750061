import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const getEntity = (entityType, entityId) => axios.get(`${BASE_URL}/api/Entities/${entityType}/${entityId}`);
const getNotes = (entityType, entityId) => axios.get(`${BASE_URL}/api/Entities/${entityType}/${entityId}/Notes`);
const addNote = (entityType, entityId, data) => axios.post(`${BASE_URL}/api/Entities/${entityType}/${entityId}/Notes`, data);
const editNote = (entityType, entityId, id, data) => axios.put(`${BASE_URL}/api/Entities/${entityType}/${entityId}/Notes/${id}`, data);
const deleteNote = (entityType, entityId, id) => axios.delete(`${BASE_URL}/api/Entities/${entityType}/${entityId}/Notes/${id}`);
const addComment = (entityType, entityId, id, data) => axios.post(`${BASE_URL}/api/Entities/${entityType}/${entityId}/${id}/Comments`, data);
const editComment = (entityType, entityId, id, commentId, data) => axios.put(`${BASE_URL}/api/Entities/${entityType}/${entityId}/${id}/Comments/${commentId}`, data);
const deleteComment = (entityType, entityId, id, commentId) => axios.delete(`${BASE_URL}/api/Entities/${entityType}/${entityId}/${id}/Comments/${commentId}`);
const addTask = (entityType, entityId, data) => axios.post(`${BASE_URL}/api/Entities/${entityType}/${entityId}/Tasks`, data);
const editTask = (entityType, entityId, id, data) => axios.put(`${BASE_URL}/api/Entities/${entityType}/${entityId}/Tasks/${id}`, data);
const deleteTask = (entityType, entityId, id) => axios.delete(`${BASE_URL}/api/Entities/${entityType}/${entityId}/Tasks/${id}`);
const complete = (entityType, entityId, id, isComplete) => axios.put(`${BASE_URL}/api/Entities/${entityType}/${entityId}/Tasks/${id}/Complete/${isComplete}`);
const tasks = (model, cancelToken) => axios.post(`${BASE_URL}/api/Entities/All/Tasks`, model, {
    cancelToken,
});

export default {
    getEntity,
    getNotes,
    addNote,
    editNote,
    deleteNote,
    addComment,
    editComment,
    deleteComment,
    addTask,
    editTask,
    deleteTask,
    complete,
    tasks,
};
